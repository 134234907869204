<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Teklif</span>
        </template>
        <validation-observer ref="simpleRules">
          <offer-form />
          <div
            v-for="(lineData,key) in dataItem.lines"
            :key="key"
          >
            <offer-product
              :remove-item="removeItemData"
              :data-key="key"
            />
          </div>
          <term-card />
          <b-row>
            <b-col>
              <b-button
                :disabled="dataItem.submitStatus"
                variant="primary"
                @click="submitData"
              >
                Kaydet
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Kartı</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>

  </div>
</template>
<script>

import {
  BRow, BCol, BButton, BTab, BTabs,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import OfferForm from '@/views/Offers/Components/OfferForm.vue'
import OfferProduct from '@/views/Offers/Components/Products.vue'
import TermCard from '@/views/Offers/Components/TermCard.vue'
import Customer from '@/views/Crm/View/Customer.vue'

export default {
  name: 'OfferAdd',
  components: {
    BTabs,
    Customer,
    BTab,
    TermCard,
    OfferProduct,
    OfferForm,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
    lineData() {
      const lineData = this.$store.getters['offers/getOffer']
      return lineData.lines
    },
    offerLinePriceItems() {
      return this.$store.getters['offerLinePriceItems/getOfferLinePriceItems']
    },
    offerTerm() {
      return this.$store.getters['offers/getTermsContent']
    },
    saveData() {
      return this.$store.getters['offers/getOfferSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.dataItem.submitStatus = false
    },
  },
  created() {
    localize('tr')
    this.$store.commit('offers/OFFER_RESET')
    this.$store.commit('offerLines/OFFER_LINE_RESET')
    this.$store.commit('offerLinePriceItems/RESET_OFFER_LINE_PRICE_ITEMS_LIST')
    this.getData()
    this.getModels()
  },
  methods: {
    getData() {
      this.$store.dispatch('offers/offerView', this.$route.params.id)
        .then(response => {
          if (response) {
            this.getCustomer(response.id_com_customer)
            this.getPriceData(response.lines[0].id_com_model)
          }
        })
    },
    getPriceData(index) {
      this.$store.dispatch('offers/getModelInfo', index)
    },
    getCustomer(customer) {
      this.$store.dispatch('customers/customerView', customer)
    },
    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.$store.dispatch('offers/offerSave', this.dataItem)
        }
      })
    },
    getModels() {
      this.$store.dispatch('models/modelsList')
    },
    removeItemData(id, lineKey, key) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('offers/itemsDelete', key)
            .then(res => {
              if (res) {
                this.lineData[lineKey].price_items.splice(id, 1)
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
